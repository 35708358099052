.MuiTextField-root .Mui-focused .MuiOutlinedInput-notchedOutline {
	border-width: 1px; /* Change this to '0' if you want to completely remove the border */
	border-color: transparent; /* Use 'transparent' if you want a completely transparent border */
}

.MuiInputBase-input
	.MuiOutlinedInput-input
	.MuiInputBase-inputSizeSmall
	.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
	font-size: 6px;
}

.form__main {
	padding: 40px 20px;

	@media only screen and (max-width: 700px) {
		padding: 30px 8px;
	}
	// .css-zow5z4-MuiGrid-root > .MuiGrid-item {
	// 	padding-top: 10px;
	// }

	.text__field {
		height: 35px;
		@media only screen and (max-width: 700px) {
			height: 45px;
		}
		::placeholder{
			font-size: 12px
		}
		input{
			margin-left: 12px;
			margin-top: 10px;
		}
	}

	.dob__text__field {
		height: 35px;
		@media only screen and (max-width: 700px) {
			height: 45px;
		}
		::placeholder{
			font-size: 12px
		}
		input{
			margin-left: 12px;
		}
	}

	.input-label {
		font-size: 11px;
		color: rgb(154, 152, 152);
		&.Mui-focused {
			margin-top: 7.8px;
		}
		@media only screen and (max-width: 700px) {
			font-size: 12px;
		}
	}

	.style-field {
		background: #fff;
		height: 40px;
		@media only screen and (max-width: 700px) {
			height: 48px;
		}
	}

	.no-border {
		border: none;

		.MuiInputBase-root {
			&:before {
				border-bottom: none; // Remove the border on focus
			}
		}

		.MuiInput-underline:after {
			border-bottom: none; // Remove the border when active
		}

		.MuiInput-underline:hover:not(.Mui-disabled):before {
			border-bottom: none; // Remove the border on hover
		}
	}
	
	.MuiFormHelperText-root.Mui-error {
		// margin-top: 10px;
		background: #eaf3ff; // Remove the background color
	}
	.MuiFormLabel-root.Mui-error {
		color: #626262; // Change the label text color when there's an error
	}

	.consent__container {
		@media (min-width: 700px) {
			margin: 30px;
		}
		.consent__label {
			font-size: 12px; /* You can adjust the size as needed */
		}
		.consent__check {
			@media (max-width: 700px) {
				align-items: start;
			}
		}
	}
	.consent__container > * {
		margin-bottom: 20px; /* Adjust the margin size as needed */
	}
	.otp-input__main {
		display: flex;
		flex-direction: column;
		gap: 24px;

		@media (min-width: 768px) {
			flex-direction: row;
			.otp {
				display: flex;
				flex-direction: column;
				input {
					width: 250px;
					height: 40px;
				}
				a {
					margin-top: 10px;
					align-self: flex-end;
					color: #0b58ff;
				}
			}
		}

		.otp {
			display: flex;
			flex-direction: column;
			a {
				margin-top: 10px;
				font-size: 12px;
				align-self: flex-end;
				color: #0b58ff;
			}
		}
	}

	.footer {
		display: flex;
		flex-direction: column;
		padding-top: 15px;
		gap: 10px;
		@media only screen and (max-width: 700px) {
			align-items: center;
			justify-content: center;
		}
		.next__btn {
			width: 156px;
			height: 40px;
			background: #5476ff 0% 0% no-repeat padding-box;
		}
		.mobile__stepper {
			width: 100%;
    		display: flex;
    		align-items: center;
    		justify-content: center;
		}
	}
}

.result__main {
	max-width: 600px;
	border-radius: 4px;
	margin: 24px;
	padding: 64px 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	gap: 24px;

	@media only screen and (max-width: 600px) {
		padding: 32px;
		gap: 12px;
	}

	p {
		font-size: larger;
		@media only screen and (max-width: 600px) {
			font-size: medium;
		}
	}
}
