$mobile: only screen and (max-width: 600px);

.wrapper {
    width: 100%;
    @media only screen and (max-width: 700px) {
        overflow-x: hidden;
    }

    .header__logo {
        margin: 0.8rem 1.2rem;
        @media only screen and (max-width: 700px) {
            display: flex;
            justify-content: center;
        }
    }

    .main {
        margin-left: 8rem;

        @media only screen and (max-width: 700px) {
            margin-left: 0; // Adjust the margin for smaller screens
            overflow-x: hidden;
        }
        .header__title {
            display: flex;
            align-items: center;
            // margin: 1rem;
            @media only screen and (min-width: 700px) {
                margin-left: 40px;
            }
            @media only screen and (max-width: 700px) {
                justify-content: center;
                h2{
                    font-size: 18px;
                    text-align: center;
                }
            }
        }

        .smart__credit__banner{
            border: 1px solid #0B58FF;
            margin: 0.6rem;
            border-radius: 8px;
            background-size: cover;
            background-image: url("../../assets/smart_credit_banner.svg");
            padding: 10px 20px;

            @media only screen and (min-width: 700px) {  
                margin-left: 38px;
                max-width: 773px;
                background-image: url("../../assets/smart_credit_desktop_banner.svg");
            }
            
            p{
                all: unset;
                font-size: 14px;
            }
            ul{
                list-style-type: none;
                padding-inline-start: 0;
                margin: 7px 0;
            }
            li{
                display: flex;
                gap: 10px;
                margin-bottom: 10px;
            }
        }

        .container {
            display: flex;
            margin: 2rem;

            @media only screen and (max-width: 700px) {
                display: block; // Change to a block layout for smaller screens
                margin: 0.6rem;
            }

            .form__container {
                margin-top: 5px;
                width: 62%;
                background: #EAF3FF;
                border-radius: 25px;
                box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.2);

                @media only screen and (max-width: 700px) {
                    border-radius: 10px;
                    width: 100%; // Make the form container full width for smaller screens
                }
            }

            .image__container {
                margin-bottom: 0;
                img {
                    max-width: 100%; // Ensures the image doesn't exceed its container
                    height: auto; // Maintains aspect ratio
                    margin: 0 auto; // Horizontally centers the image

                    @media only screen and (max-width: 1200px) {
                        display: none; // Hide the image for mobile screens
                    }
                }
            }
        }

        .our__benefits {
            display: flex;
            align-items: center;
            margin: 0 2rem;
            gap: 1rem;

            @media only screen and (max-width: 700px) {
                margin: 1rem; // Adjust the margin for smaller screens
            }
        }
        .our__benefits__mobview{
            display: flex;
            gap: 1rem;
            background: #EAF3FF;
            padding: 0.3rem 0.5rem;
            font-size: 12px;
            border-radius: 8px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
            margin: 0.6rem;
            div{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 0.8rem;
            }
        }

        .our_partners {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            flex-wrap: wrap;
            margin: 0 2rem;
            p {
                margin: 16px 0;
            }
            .items {
                display: flex;
                gap: 2rem;
            }

            @media only screen and (max-width: 700px) {
                margin: 0 0.8rem;
            }
        }

        .about {
            margin: 0.8rem 2rem;
            max-width: 775.1px;
            background: #EAF3FF;
            border-radius: 15px;
            padding: 20px;
            font-weight: 400;
            box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.2);
            p {
                font-weight: bold;
                margin: 5px 0;
            }
            span{
                font-size: 12px;
                line-height: 1px;
            }

            @media only screen and (max-width: 700px) {
                margin: 1rem; // Adjust the margin for smaller screens
            }
        }
    }
}
